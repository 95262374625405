<i18n>
[
    "global__submit",
    "global__done",
]
</i18n>

<template>
    <div
        :class="{
            'c-email-subscribe': true,
            'c-email-subscribe--modal': isModal,
            'c-email-subscribe--light': isLight && !isModal,
            'c-email-subscribe--error': !!serviceErrorMessage
        }"
    >
        <LazyLoad
            v-if="(isModal && !showSuccessContent) || !isModal"
            :observerCallback="handleObserverCallback"
            class="c-email-subscribe__form-screen"
        >
            <div
                :class="{
                    'o-row': true,
                    'o-row--collapse-small': isModal,
                    'u-spacer--4': true,
                }"
            >
                <div :class="contentContainerStyles">
                    <img
                        alt=""
                        :src="FlowersHandIllustration"
                        :class="{
                            'o-illustration--48px': !isModal,
                            'o-illustration--104px': isModal,
                            'c-email-subscribe__illustration': true,
                            'c-email-subscribe__illustration--modal': isModal,
                        }"
                    >
                    <div class="c-email-subscribe__content">
                        <Component
                            :is="headerComponent"
                            :class="{
                                'o-text--heading-3 u-spacer--1': isModal,
                            }"
                        >
                            {{ modalText.header }}
                        </Component>
                        <p
                            :class="{
                                'u-text--gray': true,
                                'u-spacer--5': isModal,
                                'u-spacer--0pt5': !isModal
                            }"
                        >
                            {{ modalText.subheader }}
                        </p>
                        <BaseMarkdown
                            v-if="!isModal"
                            :source="$t('emailCapture__privacyPolicyLink')"
                            class="o-text--caption u-text--gray"
                        />
                    </div>
                </div>
                <div
                    :class="{
                        'c-email-subscribe__form-container': true,
                        'c-email-subscribe__form-container--modal': isModal,
                        'o-extra-small--12': true,
                        'o-large--6 o-large--offset-3': isModal,
                        'o-medium--8 o-medium--offset-2': isModal,
                        'o-large--6': !isModal,
                        'o-medium--10 o-medium--offset-1': !isModal,
                    }"
                >
                    <ErrorBanner
                        v-if="serviceErrorMessage"
                        :errorText="serviceErrorMessage"
                        :light="false"
                        :shouldScrollIn="false"
                        class="u-spacer--3"
                    />
                    <BaseForm
                        ref="emailSubscribeForm"
                        :onSubmit="handleSubmit"
                        :submitButton="{
                            text: $t('global__submit'),
                            class: 'c-email-subscribe__submit-button'
                        }"
                        :shouldSetMinLoadTime="true"
                        :shouldAllowResubmission="true"
                        :class="{
                            'c-email-subscribe__form': true,
                            'c-email-subscribe__form--modal': isModal,
                        }"
                    >
                        <EmailInput
                            ref="emailInput"
                            v-model="email"
                            :labelText="$t('account__email--label')"
                            :successText="successText"
                            name="email"
                            :class="{
                                'c-email-subscribe__input': true,
                                'u-spacer--7': isModal,
                            }"
                        />
                    </BaseForm>
                </div>
            </div>
            <div v-if="isModal" class="o-row">
                <div
                    :class="[
                        'c-email-subscribe__opt-in-text-container',
                        'o-extra-small--12',
                        'o-medium--8 o-medium--offset-2',
                        'o-large--6 o-large--offset-3',
                    ]"
                >
                    <BaseMarkdown
                        :source="modalText.optIn"
                        class="o-text--caption u-text--gray u-text--center"
                    />
                </div>
            </div>
        </LazyLoad>
        <div
            v-if="isModal"
            :class="{
                'c-email-subscribe__success-screen': true,
                'c-email-subscribe__success-screen--show': showSuccessContent,
                'u-spacer--4': true
            }"
        >
            <div class="o-row">
                <div class="o-extra-small--12 o-large--6 o-large--offset-3">
                    <img
                        alt=""
                        :src="DancersIllustration"
                        class="o-illustration--104px u-spacer--3"
                    >
                    <h5 class="o-text--heading-3 u-spacer--1">
                        {{ modalSuccessText.header }}
                    </h5>
                    <p class="u-spacer--4">
                        {{ modalSuccessText.subheader }}
                    </p>
                    <div class="c-email-subscribe__success-screen-buttons">
                        <BaseButton
                            variant="secondary"
                            class="c-email-subscribe__success-screen-button"
                            @click="popModal"
                        >
                            {{ $t('global__done') }}
                        </BaseButton>
                        <BaseButton @click="goToJoinRoute">
                            {{ $t('emailCapture__modal--joinNowCta') }}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { get } from 'lodash-es';

import { subscribeEmail } from '~coreModules/profile/js/profile-api';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import {
    EMAIL_SUBSCRIBE,
    NOTIFICATION_VIEWED,
    NOTIFICATION_DISMISSED,
} from '~coreModules/core/js/global-event-constants';
import { MODALS_MODULE_NAME, POP_MODAL } from '~coreModules/modals/js/modals-store';
import { genericRouteNames } from '~coreModules/core/js/router-constants';
import { PROFILE_MODULE_NAME, SET_EMAIL_ADDRESS } from '~coreModules/profile/js/profile-store';
import {
    NOTIFICATION_TYPES,
    ANONYMOUS_EMAIL_SUBSCRIBE_SLUG,
    NOTIFICATION_DISMISS,
} from '~coreModules/analytics/js/analytics-constants';

import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';
import BaseForm from '~coreModules/core/components/ui/forms/BaseForm.vue';
import ErrorBanner from '~coreModules/core/components/errors/ErrorBanner.vue';
import EmailInput from '~coreModules/core/components/ui/form-elements/text-inputs/EmailInput.vue';
import BaseMarkdown from '~coreModules/core/components/ui/BaseMarkdown.vue';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

import FlowersHandIllustration from '~coreImages/illustration__flowers.png';
import DancersIllustration from '~coreImages/illustration__dancers.png';

export default {
    name: 'AnonymousEmailSubscribe',
    components: {
        LazyLoad,
        BaseForm,
        ErrorBanner,
        EmailInput,
        BaseMarkdown,
        BaseButton,
    },
    props: {
        isModal: {
            type: Boolean,
            default: false,
        },
        isLight: {
            type: Boolean,
            default: true,
        },
        optInLocation: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            email: '',
            FlowersHandIllustration,
            DancersIllustration,
            successText: '',
            serviceErrorMessage: '',
            showSuccessContent: false,
            stickyNotificationViewed: false,
        };
    },
    computed: {
        contentContainerStyles() {
            return {
                'c-email-subscribe__content-container': true,
                'c-email-subscribe__content-container--modal': this.isModal,
                'o-extra-small--12': true,
                'o-large--6 o-large--offset-3': this.isModal,
                'o-medium--8 o-medium--offset-2': this.isModal,
                'o-large--4 o-large--offset-1': !this.isModal,
                'o-medium--10 o-medium--offset-1': !this.isModal,
            };
        },
        headerComponent() {
            if (this.isModal) {
                return 'h2';
            }

            return 'h5';
        },
        modalText() {
            if (this.isModal) {
                return {
                    header: this.$t('emailCapture__modal--header'),
                    subheader: this.$t('emailCapture__modal--subheader'),
                    optIn: this.$t('emailCapture__optInText'),
                };
            }

            return {
                header: this.$t('emailCapture__footer--header'),
                subheader: this.$t('emailCapture__footer--subheader'),
                optIn: this.$t('emailCapture__optInText'),
            };
        },
        modalSuccessText() {
            return {
                header: this.$t('emailCapture__modal--successHeader'),
                subheader: this.$t('emailCapture__modal--successSubheader'),
            };
        },

    },
    beforeUnmount() {
        if (this.isModal) {
            this.trackGlobalEvent({
                type: NOTIFICATION_DISMISSED,
                data: {
                    notificationType: NOTIFICATION_TYPES.modal,
                    notificationSlug: ANONYMOUS_EMAIL_SUBSCRIBE_SLUG,
                    destination: NOTIFICATION_DISMISS,
                },
            });
        }
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        ...mapMutations(MODALS_MODULE_NAME, {
            popModal: POP_MODAL,
        }),
        ...mapMutations(PROFILE_MODULE_NAME, {
            setEmailAddress: SET_EMAIL_ADDRESS,
        }),
        setSuccessMessage() {
            if (this.isModal) {
                this.showSuccessContent = true;
            } else {
                setTimeout(() => {
                    this.successText = this.$t('emailCapture__footer--success');
                }, 500);

                this.resetForm();
            }
        },
        resetForm() {
            setTimeout(() => {
                this.successText = '';
                this.$refs.emailSubscribeForm.reset();
            }, 3000);
        },
        handleSubmit() {
            this.serviceErrorMessage = '';

            return subscribeEmail(this.$r15Svc, this.email, this.optInLocation)
                .then((res) => {
                    this.trackGlobalEvent({
                        type: EMAIL_SUBSCRIBE,
                        data: {
                            optInLocation: this.isModal ? 'modal' : 'footer',
                            traceId: res.responseHeaders?.traceId,
                        },
                    });
                })
                .then(() => this.setSuccessMessage())
                .catch((error) => {
                    this.$logger.debugError('Failed to subscribe user to emails: ', error);
                    this.serviceErrorMessage = get(error, 'data.message', '');
                });
        },
        goToJoinRoute() {
            try {
                const joinRoute = this.$router.resolve({ name: genericRouteNames.join.base });
                this.setEmailAddress(this.email);
                this.$router.push(joinRoute);
            } catch (error) {
                window.location.href = '/rent/join';
            }
        },
        handleObserverCallback([{ isIntersecting }]) {
            if (isIntersecting && !this.isModal && !this.stickyNotificationViewed) {
                this.stickyNotificationViewed = true;
                this.trackGlobalEvent({
                    type: NOTIFICATION_VIEWED,
                    data: {
                        notificationType: NOTIFICATION_TYPES.stickyNotification,
                        notificationSlug: ANONYMOUS_EMAIL_SUBSCRIBE_SLUG,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss">
    .c-email-subscribe {
        $this: &;

        padding-top: $nu-spacer-6;
        padding-bottom: $nu-spacer-4;
        text-align: center;
        background-color: $nu-secondary;

        @include breakpoint(large) {
            padding-top: $nu-spacer-5;
            padding-bottom: $nu-spacer-1pt5;
        }

        &--light {
            background-color: $nu-white;
        }

        &:not(&--modal) {
            @include breakpoint(large) {
                text-align: left;
            }
        }

        &--modal {
            text-align: center;
            padding: $nu-spacer-6 0 $nu-spacer-8;

            @include breakpoint(large) {
                padding: $nu-spacer-8 0 $nu-spacer-12;
            }
        }

        &--error {
            #{$this}__content-container {
                @include breakpoint(large) {
                    align-items: flex-end;
                }
            }
        }

        &__form-container {
            &:not(&--modal) {
                @include breakpoint(large) {
                    grid-column-start: auto;
                }
            }
        }

        &__success-screen {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            opacity: 0;
            transition: opacity .5s ease;
            margin-bottom: $nu-spacer-4;
            z-index: -1;

            &--show {
                opacity: 1;
                z-index: 0;
            }

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }

        &__success-screen-buttons {
            display: flex;
            justify-content: center;
        }

        &__success-screen-button {
            margin-right: $nu-spacer-3;
        }

        &__illustration {
            display: inline-block;
            vertical-align: middle;
            height: 48px;
            margin-bottom: $nu-spacer-1;

            @include breakpoint(large) {
                margin-bottom: 0;
                margin-right: $nu-spacer-2;
            }

            &--modal {
                height: auto;
                margin-bottom: $nu-spacer-3;
                margin-right: 0;
            }
        }

        &__content-container {
            margin-bottom: $nu-spacer-3;

            @include breakpoint(large) {
                display: flex;
                margin-bottom: 0;
            }

            &--modal {
                display: block;
            }
        }

        &__form {
            &:not(&--modal) {
                form {
                     @include breakpoint(medium) {
                        margin-bottom: $nu-spacer-2;
                        display: flex;
                    }

                    @include breakpoint(large) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__input {
            height: 48px;
            margin-bottom: $nu-spacer-4;
            padding-top: $nu-spacer-1pt5;

            @include breakpoint(medium) {
                margin-bottom: 0;
                padding-right: $nu-spacer-3;
            }
        }

        &__submit-button {
            width: 100%;

            @include breakpoint(medium) {
                width: 202px;
            }
        }
    }
</style>
